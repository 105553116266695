import general from '../../utils/general';
import axios from 'axios';
import { plan } from './types'
import { useEffect, useState, useMemo, useRef } from 'react';
import { loadSlim } from "@tsparticles/slim";
import { initParticlesEngine } from "@tsparticles/react";
import {
  type Container,
  type ISourceOptions,
  MoveDirection,
  OutMode,
} from "@tsparticles/engine";

export const useController = () => {
  const [init, setInit] = useState(false);
  const particlesLoaded = (container?: Container) => { };
  const [plans, setPlans] = useState<plan[]>([]);
  const [planShowing, setPlanShowing] = useState(0);
  const [planPeriod, setPlanPeriod] = useState(2);
  const [currentFunctionShowing, setCurrentFunctionShowing] = useState(0);
  const [formSent, setFormSent] = useState(false);
  const firstClick = useRef(false);
  const [keyBenefits, setKeyBenefits] = useState([
    {
      title: 'Fácil de Usar',
      description: 'Comece agora mesmo! Não precisa ser especialista em tecnologia – em minutos você estará organizando seu negócio com facilidade.'
    },
    {
      title: 'Resultados Rápidos',
      description: 'Veja melhorias imediatas na organização e produtividade. A gestão eficiente começa no dia em que você ativa seu acesso.'
    },
    {
      title: 'Reduza o Trabalho Manual',
      description: 'Economize tempo e esforço ao simplificar as tarefas do dia a dia. Gerencie seu negócio com mais eficiência e tranquilidade.'
    },
    {
      title: 'Sistema Online e Responsivo',
      description: 'Acesse tudo de qualquer lugar, a qualquer hora. Tenha sua empresa na palma da mão, com segurança e praticidade.'
    },
    {
      title: 'Decisões Mais Inteligentes',
      description: 'Dados claros mostram exatamente onde melhorar e onde investir tempo e dinheiro. Informação rápida para decisões certeiras.'
    },
    {
      title: 'Risco Zero – Teste Agora',
      description: 'Sem compromisso, sem cartão de crédito. Teste por 14 dias e veja se é a solução que você procura.'
    },
    {
      title: 'Suporte Sempre ao Seu Lado',
      description: 'Dúvidas? Estamos disponível por WhatsApp e e-mail para te ajudar em qualquer momento.'
    },
    {
      title: 'Seu Negócio no Controle',
      description: 'Chega de desorganização. Planeje, execute e cresça com um sistema feito para simplificar sua vida e aumentar seus lucros.'
    },
  ])
  const [functionalities, setFunctionalities] = useState([
    {
      icon: 'faColumns',
      title: 'Organize Processos com Eficiência',
      image: "flows.png",
      description: 'O módulo de Fluxos, baseado no conceito Kanban, é a ferramenta ideal para organizar e acompanhar processos de forma visual e prática. Ele permite criar quadros personalizados para diferentes fluxos de trabalho, como tarefas, funil de vendas, projetos ou processos internos. Com uma interface intuitiva, você pode mover cards entre colunas, representando o progresso de cada etapa com clareza e agilidade. Essa funcionalidade promove a colaboração entre equipes, garantindo que todos estejam alinhados e focados nos objetivos. Otimize a produtividade, visualize gargalos e mantenha o controle total sobre as operações com o módulo Fluxos.'
    },
    {
      icon: 'faDollar',
      title: 'Controle Financeiro Completo e Eficiente',
      image: "finances.png",
      description: 'O módulo de Finanças coloca você no comando absoluto das finanças da sua empresa. Com ele, você pode gerenciar contas a pagar e a receber de forma integrada, acompanhar o fluxo de caixa em tempo real e garantir uma visão clara e precisa da saúde financeira do seu negócio. Mantenha-se sempre um passo à frente, evitando erros comuns, automatizando processos, e otimizando o controle de despesas. Isso não apenas reduz custos, mas também melhora a lucratividade e a eficiência financeira da sua empresa, proporcionando uma gestão mais estratégica e sustentável.'
    },
    {
      icon: 'faChartPie',
      title: 'Visualize o Sucesso da Sua Empresa em Um Só Lugar',
      image: "dashboard.png",
      description: 'Obtenha uma visão abrangente e completa do desempenho da sua empresa com o Arezdot ERP. Por meio de gráficos intuitivos e dados detalhados em tempo real, você pode acompanhar os KPIs mais relevantes, monitorar o progresso em direção aos seus objetivos estratégicos e identificar rapidamente oportunidades de melhoria. Independentemente de estar analisando vendas, controlando o fluxo financeiro ou avaliando a produtividade da equipe, o Arezdot ERP centraliza todas as informações essenciais, permitindo decisões estratégicas embasadas, rápidas e precisas. Transforme dados em insights valiosos para impulsionar o crescimento sustentável da sua empresa.'
    },
    {
      icon: 'faBoxesStacked',
      title: 'Controle Absoluto de Suprimentos e Inventário',
      image: "stock.png",
      description: 'Elimine de vez os problemas com falta de produtos ou excesso de estoque. O módulo de Estoque oferece um controle avançado e detalhado do seu inventário, permitindo monitorar de forma precisa a entrada e saída de mercadorias. Defina níveis mínimos e máximos para cada item, garantindo que você nunca fique sem produtos essenciais, ao mesmo tempo em que evita o acúmulo desnecessário. Com uma interface simples e intuitiva, você acompanha o fluxo do seu estoque em tempo real, otimizando suas decisões. O resultado? Redução de custos, maior eficiência operacional e uma gestão de estoque muito mais inteligente e ágil.'
    },
    {
      icon: 'faPenToSquare',
      title: 'Capture Dados de Forma Fácil e Eficiente',
      image: "forms.png",
      description: 'Crie e gerencie formulários personalizados para capturar informações valiosas de clientes, leads e funcionários. Este módulo facilita a criação de pesquisas, feedbacks e formulários de solicitação, permitindo que sua empresa colete dados essenciais de forma prática e organizada. Com uma interface intuitiva, você centraliza todas as informações em um só lugar, melhorando a gestão e a análise de dados importantes para o sucesso do seu negócio. Além das pesquisas e formulários tradicionais, este módulo oferece a opção de criar formulários de agendamento personalizados. Com eles, você pode organizar compromissos, reuniões ou reservas de forma automatizada, otimizando a experiência dos seus clientes e a produtividade da sua equipe. Simplifique o processo de agendamento e garanta que todos os eventos estejam bem estruturados e sincronizados.'
    },
    {
      icon: 'faCalendar',
      title: 'Organização e Planejamento em Dia',
      image: "schedule.png",
      description: 'Gerencie compromissos, reuniões e prazos críticos com eficiência utilizando o módulo de Agenda. Este recurso permite que você sincronize eventos e tarefas em um só lugar, garantindo que tudo esteja devidamente organizado e planejado. Com a facilidade de visualização e atualização em tempo real, sua equipe permanece alinhada, evitando conflitos de horário e garantindo o cumprimento de prazos. Mantenha o controle sobre todos os compromissos importantes e otimize o planejamento diário para uma gestão mais fluida e produtiva.'
    },
    {
      icon: 'faRobot',
      title: 'Inteligência Artificial: Transforme Dados em Decisões Poderosas',
      image: "iaQuestion.png",
      description: 'A inteligência artificial é uma aliada poderosa para transformar dados em decisões estratégicas de alto impacto. Com este módulo, você pode criar prompts personalizados que direcionam ações precisas, identificam oportunidades escondidas e melhoram significativamente a eficiência operacional. Ao aproveitar o poder da IA, sua empresa pode otimizar processos, prever tendências de mercado e automatizar tarefas críticas, permitindo uma tomada de decisões mais rápida e embasada. Essa tecnologia impulsiona o crescimento sustentável, proporcionando uma vantagem competitiva que eleva o desempenho e a inovação em todos os níveis do negócio.'
    },
    {
      icon: 'faBarsProgress',
      title: 'Acompanhe e Alcance Seus Objetivos',
      image: "goals.png",
      description: 'Estabeleça, monitore e conquiste as metas estratégicas da sua empresa com eficiência por meio do módulo de Metas. Com ele, você obtém uma visão em tempo real do progresso de cada meta, permitindo um acompanhamento contínuo e detalhado. Essa funcionalidade oferece a flexibilidade de ajustar ações rapidamente conforme necessário, assegurando que sua equipe permaneça alinhada com os objetivos traçados e possa reagir prontamente a desafios ou mudanças. Com essa ferramenta, você garante que o desempenho de sua empresa seja otimizado, mantendo o foco no crescimento e no sucesso sustentável.'
    },
    {
      icon: 'faFilterCircleDollar',
      title: 'Conquiste Novos Clientes de Forma Estratégica',
      image: "leads.png",
      description: 'Converta leads em clientes de forma estratégica e eficiente com o módulo de Gestão de Leads. Centralize o registro, categorização e gerenciamento de todos os seus leads em um único lugar, otimizando cada etapa do funil de vendas. Com essa visão organizada, você consegue identificar oportunidades valiosas, acompanhar o progresso de cada lead em tempo real e ajustar suas abordagens para maximizar as chances de conversão. Aumente a eficiência da sua equipe de vendas e feche negócios de maneira mais rápida e assertiva, transformando potenciais clientes em parceiros de longo prazo.'
    },
    {
      icon: 'faUser',
      title: 'Relacionamento Inteligente para Fidelização',
      image: "clients.png",
      description: 'Conheça profundamente seus clientes e construa relacionamentos duradouros. O módulo de Clientes permite registrar e gerenciar informações essenciais sobre cada cliente, como histórico de compras, preferências e feedbacks. Com esses dados em mãos, você pode personalizar o atendimento, oferecer soluções sob medida e antecipar as necessidades dos seus clientes, aumentando significativamente a satisfação e a fidelização. Além disso, ao entender melhor o comportamento de seus consumidores, você aprimora a experiência deles com sua empresa, criando conexões mais fortes e garantindo um relacionamento de longo prazo baseado em confiança e valor agregado.'
    },
    {
      icon: 'faUserPen',
      title: 'Personalização e Linha do Tempo Integrada',
      image: "customFieldsAndTimeline.png",
      description: 'Torne cada item único com campos customizáveis e acompanhe todo o histórico de ações com uma linha do tempo detalhada. Este módulo permite adicionar informações personalizadas específicas para cada item do sistema, adaptando o mesmo às necessidades do seu negócio. Além disso, com a linha do tempo integrada, você pode monitorar e registrar todas as alterações realizadas, mantendo um histórico claro e organizado de cada interação. Ideal para empresas que valorizam flexibilidade e rastreabilidade em suas operações.'
    },
    {
      icon: 'faUserGroup',
      title: 'Potencialize o Desempenho do Seu Time',
      image: "collaborators.png",
      description: 'A gestão de pessoas é um pilar fundamental para o sucesso de qualquer negócio. Com o módulo de Recursos Humanos, você simplifica o desenvolvimento e a administração das suas equipes. Ele oferece ferramentas que otimizam processos como recrutamento, treinamento, acompanhamento de desempenho e gestão de benefícios, permitindo que você se concentre no que realmente importa: o crescimento profissional e a satisfação do seu time. Ao investir no bem-estar e na capacitação dos colaboradores, você cria um ambiente de trabalho mais produtivo e motivado, contribuindo diretamente para o sucesso e a sustentabilidade da sua empresa.'
    },
    {
      icon: 'faLayerGroup',
      title: 'Organização Intuitiva para Melhor Gestão',
      image: "categories.png",
      description: 'Organize seus produtos e serviços de maneira inteligente e eficiente. Com uma estruturação estratégica, você pode segmentar e gerenciar seus inventários com precisão, facilitando a navegação e a análise de dados essenciais. Isso resulta em uma gestão mais ágil e simplificada, permitindo que sua equipe execute operações com maior rapidez e eficiência. Além disso, a organização otimizada garante que você localize rapidamente os itens ou serviços que seus clientes demandam, proporcionando uma experiência mais ágil e satisfatória. Maximize a produtividade e otimize o atendimento ao cliente com uma gestão inteligente de seus recursos.'
    },
    {
      icon: 'faScrewdriverWrench',
      title: 'Controle e Gestão Simplificados de Serviços',
      image: "services.png",
      description: 'O módulo de Serviços oferece uma solução completa para cadastrar e gerenciar os serviços oferecidos pela sua empresa de forma organizada e eficiente. Através dele, você pode monitorar a frequência de vendas de cada serviço, obtendo insights valiosos sobre sua popularidade e rentabilidade. Com esses dados em mãos, fica mais fácil identificar quais serviços estão gerando maior demanda e quais são os mais lucrativos, permitindo otimizar suas estratégias e oferecer um portfólio alinhado com as necessidades do mercado. Ganhe controle total sobre os serviços prestados e potencialize os resultados do seu negócio.'
    },
    {
      icon: 'faShieldHalved',
      title: 'Segurança e Controle de Acesso',
      image: "accessControl.png",
      description: 'Proteja seus dados de forma robusta e garanta que apenas usuários autorizados tenham acesso ao sistema. O módulo de Segurança permite que você configure níveis de permissão detalhados, controlando exatamente quem pode acessar cada área do sistema e restringindo o acesso a informações sensíveis. Com essa proteção reforçada, você assegura a integridade dos dados e minimiza riscos, proporcionando maior segurança para a sua empresa e tranquilidade para sua equipe.'
    },
    {
      icon: 'faBuilding',
      title: 'Gestão Multinível com Facilidade',
      image: "multipleCompanies.png",
      description: 'Gerencie múltiplas empresas ou filiais com total simplicidade e eficiência. Com este módulo, você pode alternar facilmente entre diferentes empresas, permitindo um controle centralizado, mas flexível, para quem administra mais de uma empresa ou filial. Cada empresa opera com um plano distinto, garantindo uma gestão individualizada e organizada. Essa funcionalidade oferece a você total visibilidade e controle sobre todas as operações, facilitando a tomada de decisões estratégicas e o monitoramento de resultados em diversas frentes, sem complicação.'
    },
    {
      icon: 'faTruckField',
      title: 'Relacionamento e Gestão Simplificados dos seus fornecedores',
      image: "suppliers.png",
      description: 'Centralize todas as informações dos seus fornecedores em um único lugar e construa um relacionamento estratégico com cada um deles. Com este módulo, você pode gerenciar de forma eficaz o relacionamento com seus fornecedores, acompanhando desde o histórico de transações até a qualidade dos produtos e serviços fornecidos. Isso permite que sua empresa mantenha padrões elevados de qualidade, negociando de forma mais assertiva e garantindo parcerias sólidas e produtivas. Com um controle aprimorado, você otimiza o processo de compras, minimiza riscos e fortalece a cadeia de suprimentos, assegurando o sucesso das suas operações.'
    },
    {
      icon: 'faShapes',
      title: 'Ferramentas Complementares para Aumentar a Produtividade',
      image: "utils.png",
      description: 'Eleve a produtividade da sua empresa com as utilidades integradas do ERP. Este módulo disponibiliza uma série de ferramentas essenciais que facilitam a execução de tarefas cotidianas, como consultas rápidas de CNPJ, conversão de moedas com taxas atualizadas em tempo real, e muito mais. Essas funcionalidades otimizam o tempo da sua equipe, proporcionando agilidade nas operações e maior precisão nas informações. Ideal para empresas que buscam eficiência máxima e desejam automatizar processos, este módulo se torna indispensável para quem valoriza praticidade e resultados rápidos.'
    },
    {
      icon: 'faHouseCircleCheck',
      title: 'Controle de Ativos Empresariais',
      image: "patrimony.png",
      description: 'Gerencie os ativos da sua empresa com praticidade e eficiência. O módulo de Patrimônio permite que você registre, rastreie e monitore todos os bens da organização, garantindo que eles estejam sempre em bom estado e prontos para uso. Com essa gestão centralizada, você reduz custos, evita perdas e maximiza o aproveitamento dos recursos disponíveis. Mantenha total controle sobre seu patrimônio e assegure um uso inteligente dos seus ativos. Cuidar dos seus bens é investir no futuro da sua empresa.'
    },
    {
      icon: 'faCar',
      title: 'Gerenciamento Total da Frota',
      image: "vehicles.png",
      description: 'Se sua empresa possui uma frota de veículos, este módulo é indispensável. Ele permite gerenciar o histórico completo de manutenção, monitorar despesas e organizar a alocação de veículos de forma centralizada e eficiente. Com todas as informações reunidas em um único lugar, você otimiza a gestão da frota, reduz custos operacionais, evita eventos inesperados e garante que os veículos estejam sempre em perfeito funcionamento. Mantenha sua frota rodando de forma segura e econômica, maximizando o desempenho e a longevidade dos seus veículos.'
    },
    {
      icon: 'faUsers',
      title: 'Colaboração Efetiva e Alinhamento Organizacional',
      image: "teams.png",
      description: 'Forme equipes estratégicas, delegue metas claras e monitore o desempenho em tempo real para garantir que os objetivos sejam atingidos de maneira eficiente e coordenada. Com essa abordagem, você pode acompanhar o progresso de cada equipe, identificar obstáculos rapidamente e ajustar o curso das ações conforme necessário. Ao fornecer feedback contínuo e mensurável, você mantém todos alinhados com os objetivos da empresa, incentivando uma cultura de alta performance e garantindo que cada meta seja cumprida com excelência.'
    },
    {
      icon: 'faCrosshairs',
      title: 'Foco no que Você Precisa',
      image: "focusOnNeeds.png",
      description: 'O Arezdot ERP é desenvolvido com foco total nas suas necessidades. Projetado para evoluir conforme o feedback dos clientes e a análise de uso, o sistema é constantemente atualizado para garantir que você tenha sempre as ferramentas mais adequadas para gerenciar sua empresa de maneira eficiente. Cada atualização é pensada para otimizar a experiência do usuário, trazendo soluções práticas e adaptadas à realidade do seu negócio, permitindo que você maximize sua produtividade e tome decisões estratégicas com mais confiança e precisão.'
    },
    {
      icon: 'faArrowUpRightDots',
      title: 'Constante Expansão',
      image: "constantExpansion.png",
      description: 'Nosso sistema está em constante evolução, com novas funcionalidades sendo adicionadas regularmente. Isso garante que você tenha sempre acesso às ferramentas mais avançadas para gerenciar sua empresa de maneira eficiente e competitiva. Com atualizações frequentes, oferecemos as mais recentes inovações do mercado, sem nenhum custo adicional, permitindo que sua empresa esteja sempre à frente com tecnologia de ponta. Mantenha-se atualizado e aproveite o melhor que o mercado tem a oferecer, sem preocupações extras.'
    },
    {
      icon: 'faHeadset',
      title: 'Suporte Sempre ao Seu Lado',
      image: "support.png",
      description: 'Você terá acesso direto à nossa equipe de suporte, sempre pronta para auxiliá-lo em qualquer questão ou dúvida relacionada ao ERP. Com um atendimento ágil e eficiente, garantimos que sua empresa receba o suporte necessário para manter o sistema operando perfeitamente. Nossa equipe está comprometida em oferecer soluções rápidas e eficazes, assegurando que você possa focar no que realmente importa: o crescimento e o sucesso do seu negócio, enquanto nós cuidamos da estabilidade e do bom funcionamento do sistema.'
    },
    /* {
      icon: 'faDollar',
      title: 'Experimente Gratuitamente por 7 Dias',
      image: "freeTrial.png",
      description: 'Aproveite a oportunidade de explorar os recursos do nosso ERP sem compromisso! Durante 7 dias, você terá acesso completo ao plano escolhido, sem necessidade de fornecer informações de pagamento. Teste as funcionalidades e descubra como nossa plataforma pode lhe auxiliar em sua gestão empresarial. Não há cobrança, apenas uma experiência totalmente gratuita e sem riscos. Estamos aqui para ajudar a otimizar seus processos de forma simples e eficaz!'
    } */
  ])

  const plansPeriods = [
    {
      value: 0,
      label: 'Mensal',
      abbr: 'mês',
      planValue: 1
    },
    {
      value: 1,
      label: 'Semestral',
      abbr: 'semestre',
      planValue: 0.95
    },
    {
      value: 2,
      label: 'Anual',
      abbr: 'ano',
      planValue: 0.9
    }
  ]

  const changeFunctionShowing = (direction: 'next' | 'back') => {
    if (direction === 'next') {
      if (currentFunctionShowing < (functionalities.length - 1)) {
        setCurrentFunctionShowing(currentFunctionShowing + 1);
      } else {
        setCurrentFunctionShowing(0);
      }
    } else {
      if (currentFunctionShowing >= 1) {
        setCurrentFunctionShowing(currentFunctionShowing - 1);
      } else {
        setCurrentFunctionShowing(functionalities.length - 1);
      }
    }
  }

  const getPlans = () => {
    axios.post('https://api.arezdot.com/public/plan/list', {
      page: 0,
      limit: 9999999999999
    }).then((response) => {
      if (response.status === 200) {
        setPlans(response.data.data.data.filter((plan: any) => plan.showOnWebsite === true).sort((a: any, b: any) => a.value - b.value));
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  useEffect(() => {
    getPlans();

    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const changePlan = (direction: 'next' | 'back') => {
    if (direction === 'next') {
      if (planShowing < (plans.length - 1)) {
        setPlanShowing(planShowing + 1);
      } else {
        setPlanShowing(planShowing);
      }
    } else {
      if (planShowing >= 1) {
        setPlanShowing(planShowing - 1);
      } else {
        setPlanShowing(planShowing);
      }
    }
  }

  const options: ISourceOptions = useMemo(
    () => ({
      background: {
        color: {
          value: "#574f03",
        },
      },
      fpsLimit: 120,
      interactivity: {
        events: {
          onClick: {
            enable: true,
            mode: "push",
          },
          onHover: {
            enable: true,
            mode: "grab",
          },
        },
        modes: {
          push: {
            quantity: 1,
          },
          grab: {
            distance: 200,
            quantity: 2,
            links: {
              opacity: 1,
            },
          }
        },
      },
      particles: {
        color: {
          value: "#ffffff",
        },
        links: {
          color: "#ffffff",
          distance: 250,
          enable: true,
          opacity: 0.3,
          width: 2,
        },
        move: {
          direction: MoveDirection.none,
          enable: true,
          outModes: {
            default: OutMode.out,
          },
          random: true,
          speed: 2,
          straight: false,
        },
        number: {
          density: {
            enable: true,
          },
          value: 80,
        },
        opacity: {
          value: 0.5,
        },
        shape: {
          type: "circle",
        },
        size: {
          value: { min: 1, max: 2 },
        },
      },
      detectRetina: true,
    }),
    [],
  );

  window.onload = function () {
    /* fast click : 'fc'
        swipe left : 'swl'
        swipe right : 'swr'
        swipe up : 'swu'
        swipe down : 'swd'
    */
    (function (d: Document) {
      const ce = (e: TouchEvent, n: string): boolean => {
        const a = new CustomEvent(n, {
          bubbles: true,
          cancelable: true,
          detail: e.target,
        });
        e.target?.dispatchEvent(a);
        return false;
      };

      let nm = true;
      let sp = { x: 0, y: 0 };
      let ep = { x: 0, y: 0 };

      const touch = {
        touchstart: (e: TouchEvent) => {
          sp = {
            x: e.touches[0].pageX,
            y: e.touches[0].pageY,
          };
        },
        touchmove: (e: TouchEvent) => {
          nm = false;
          ep = {
            x: e.touches[0].pageX,
            y: e.touches[0].pageY,
          };
        },
        touchend: (e: TouchEvent) => {
          if (nm) {
            ce(e, "fc");
          } else {
            const x = ep.x - sp.x;
            const xr = Math.abs(x);
            const y = ep.y - sp.y;
            const yr = Math.abs(y);

            if (Math.max(xr, yr) > 20) {
              ce(
                e,
                xr > yr
                  ? x < 0
                    ? "swl"
                    : "swr"
                  : y < 0
                    ? "swu"
                    : "swd"
              );
            }
          }
          nm = true;
        },
        touchcancel: (e: TouchEvent) => {
          nm = false;
        },
      };

      for (const a in touch) {
        d.addEventListener(
          a,
          touch[a as keyof typeof touch] as EventListener,
          false
        );
      }
    })(document);
  };

  const changeFunction = (e: Event) => {
    if (e.type === "swl") {
      changeFunctionShowing('next');
    } else if (e.type === "swr") {
      changeFunctionShowing('back');
    }
  };

  useEffect(() => {
    document.getElementById("functionsContainer")?.addEventListener("swl", changeFunction, false);
    document.getElementById("functionsContainer")?.addEventListener("swr", changeFunction, false);

    return () => {
      document.getElementById("functionsContainer")?.removeEventListener("swl", changeFunction, false);
      document.getElementById("functionsContainer")?.removeEventListener("swr", changeFunction, false);
    };
  }, [currentFunctionShowing]);

  function checkVisible(elm: any) {
    var rect = elm.getBoundingClientRect();
    var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
  }

  function decideButtonVisibility() {
    /* Plans button */
    if (checkVisible(document.getElementById('plans'))) {
      document.getElementById('shortcutIconDivPlans')!.style.display = 'none';
    }
    else {
      document.getElementById('shortcutIconDivPlans')!.style.display = 'flex';
    }
  }

  const phoneMask = (name: string) => {
    let element: any = document.querySelector(`input[name="${name}"]`)
    let value = element.value
    const onlyNumbers = value?.replace(/[^\d]/g, '') ?? ''
    let formattedValue = onlyNumbers
    if (onlyNumbers?.length <= 2) {
      return element.value = onlyNumbers
    }
    if (onlyNumbers?.length <= 7) {
      return element.value = `(${onlyNumbers?.slice(0, 2)}) ${onlyNumbers?.slice(2)}`
    }
    if (onlyNumbers?.length <= 10) {
      formattedValue = `(${onlyNumbers?.slice(0, 2)}) ${onlyNumbers?.slice(2, 6)}-${onlyNumbers?.slice(6)}`
    }
    else {
      formattedValue = `(${onlyNumbers?.slice(0, 2)}) ${onlyNumbers?.slice(2, 7)}-${onlyNumbers?.slice(7, 11)}`
    }
    element.value = formattedValue
  }

  const submitForm = (e: any) => {
    e.preventDefault();

    const form = document.querySelector<HTMLFormElement>('#submitFormArezdot');
    if (!form) {
      console.error("Formulário não encontrado.");
      return;
    }

    let whatsapp = document.getElementById('whatsappInput') as HTMLInputElement;
    if (whatsapp.value.length < 14) {
      alert('Por favor, preencha o campo de WhatsApp corretamente.');
      return;
    }

    const url = "https://api.arezdot.com/public/form/integration/answer/67870b6104e2ace7b0c3c493";
    const xhr = new XMLHttpRequest();

    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
        console.log(xhr.responseText);
      }
    };

    const data: Record<string, string> = {};
    const inputs = form.elements; // HTMLFormControlsCollection

    for (const input of Array.from(inputs)) {
      if ((input as HTMLInputElement).name) {
        const element = input as HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
        data[element.name] = element.value;
      }
    }

    xhr.send(JSON.stringify(data));
    setFormSent(true);

    try {
      axios.post('https://discord.com/api/webhooks/' + (process.env.REACT_APP_DISCORD_URL ?? ''), {
        content: `@everyone\n\n🚀  NOVO LEAD!  🚀\nNome: ${e.target.name.value}\nWhatsApp: ${e.target.a3s4csgs6M.value}\nBora mandar mensagem!`
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        console.log(response);
      }).catch((error) => {
        console.log(error);
      });
    }
    catch (e) {
      console.log('Erro', e);
    }
  };

  const sendNotifToDiscord = () => {
    if (firstClick.current) return;
    try {
      if (process.env.REACT_APP_PRODUCTION === 'true') {
        axios.post('https://discord.com/api/webhooks/' + (process.env.REACT_APP_DISCORD_NV_URL ?? ''), {
          content: `🚀  NOVO VISITANTE em arezdot.com!  🚀`
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          console.log(response);
        }).catch((err) => {
          console.log(err);
        });
      }
    }
    catch (err) {
      console.log(err);
    }
    firstClick.current = true;
  }

  useEffect(() => {
    decideButtonVisibility();
    window.addEventListener('click', sendNotifToDiscord);
    window.addEventListener('touchstart', sendNotifToDiscord);
    window.addEventListener('mouseover', sendNotifToDiscord);

    return () => {
      window.removeEventListener('click', sendNotifToDiscord);
      window.removeEventListener('touchstart', sendNotifToDiscord);
      window.removeEventListener('mouseover', sendNotifToDiscord);
    }
  }, []);

  return {
    plans,
    general,
    planPeriod,
    planShowing,
    plansPeriods,
    functionalities,
    options,
    init,
    currentFunctionShowing,
    formSent,
    keyBenefits,
    changeFunctionShowing,
    particlesLoaded,
    setPlans,
    changePlan,
    setPlanPeriod,
    setPlanShowing,
    decideButtonVisibility,
    checkVisible,
    phoneMask,
    submitForm,
    setFormSent
  }
}

export default useController;