import general from '../../utils/general';
import axios from 'axios';
import { plan } from './types'
import { useEffect, useState, useMemo, useRef } from 'react';
import { loadSlim } from "@tsparticles/slim";
import { initParticlesEngine } from "@tsparticles/react";
import {
  type Container,
  type ISourceOptions,
  MoveDirection,
  OutMode,
} from "@tsparticles/engine";

export const useController = () => {
  const [init, setInit] = useState(false);
  const particlesLoaded = (container?: Container) => { };
  const [plans, setPlans] = useState<plan[]>([]);
  const [planShowing, setPlanShowing] = useState(0);
  const [planPeriod, setPlanPeriod] = useState(2);
  const [currentFunctionShowing, setCurrentFunctionShowing] = useState(0);
  const [formSent, setFormSent] = useState(false);
  const firstClick = useRef(false);
  const [keyBenefits, setKeyBenefits] = useState([
    {
      "title": "Fácil de Usar",
      "description": "Comece agora mesmo! Não precisa ser especialista em tecnologia – seu site será entregue pronto para uso, intuitivo e sem complicações."
    },
    {
      "title": "Rápido e Otimizado",
      "description": "Seu site será leve, rápido e otimizado para o Google, garantindo que sua empresa seja encontrada com facilidade."
    },
    {
      "title": "Menos Trabalho, Mais Resultados",
      "description": "Automatize sua presença online e atraia clientes 24h por dia. Seu site funciona para você, sem esforço adicional."
    },
    {
      "title": "100% Responsivo e Seguro",
      "description": "Acesse seu site de qualquer dispositivo – celular, tablet ou computador. Segurança e desempenho garantidos em qualquer tela."
    },
    {
      "title": "Design Profissional e Personalizado",
      "description": "Criamos um site moderno, desenvolvido para fortalecer sua marca, atrair clientes e destacar sua presença digital."
    },
    {
      "title": "Veja o Modelo Antes de Finalizar – Sem Surpresas!",
      "description": "Antes da finalização, você terá acesso a um modelo da estrutura do site, garantindo que atenda às suas necessidades sem surpresas."
    },
    {
      "title": "Suporte Rápido e Sem Complicação",
      "description": "Dúvidas ou ajustes? Nós estamos disponíveis para garantir que seu site funcione perfeitamente, sem complicações."
    },
    {
      "title": "Impulsione Seu Negócio",
      "description": "Pare de perder clientes para a concorrência. Destaque-se com um site profissional e aumente suas vendas rapidamente!"
    }
  ])

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const options: ISourceOptions = useMemo(
    () => ({
      background: {
        color: {
          value: "#574f03",
        },
      },
      fpsLimit: 120,
      interactivity: {
        events: {
          onClick: {
            enable: true,
            mode: "push",
          },
          onHover: {
            enable: true,
            mode: "grab",
          },
        },
        modes: {
          push: {
            quantity: 1,
          },
          grab: {
            distance: 200,
            quantity: 2,
            links: {
              opacity: 1,
            },
          }
        },
      },
      particles: {
        color: {
          value: "#ffffff",
        },
        links: {
          color: "#ffffff",
          distance: 250,
          enable: true,
          opacity: 0.3,
          width: 2,
        },
        move: {
          direction: MoveDirection.none,
          enable: true,
          outModes: {
            default: OutMode.out,
          },
          random: true,
          speed: 2,
          straight: false,
        },
        number: {
          density: {
            enable: true,
          },
          value: 80,
        },
        opacity: {
          value: 0.5,
        },
        shape: {
          type: "circle",
        },
        size: {
          value: { min: 1, max: 2 },
        },
      },
      detectRetina: true,
    }),
    [],
  );

  function checkVisible(elm: any) {
    var rect = elm.getBoundingClientRect();
    var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
  }

  const phoneMask = (name: string) => {
    let element: any = document.querySelector(`input[name="${name}"]`)
    let value = element.value
    const onlyNumbers = value?.replace(/[^\d]/g, '') ?? ''
    let formattedValue = onlyNumbers
    if (onlyNumbers?.length <= 2) {
      return element.value = onlyNumbers
    }
    if (onlyNumbers?.length <= 7) {
      return element.value = `(${onlyNumbers?.slice(0, 2)}) ${onlyNumbers?.slice(2)}`
    }
    if (onlyNumbers?.length <= 10) {
      formattedValue = `(${onlyNumbers?.slice(0, 2)}) ${onlyNumbers?.slice(2, 6)}-${onlyNumbers?.slice(6)}`
    }
    else {
      formattedValue = `(${onlyNumbers?.slice(0, 2)}) ${onlyNumbers?.slice(2, 7)}-${onlyNumbers?.slice(7, 11)}`
    }
    element.value = formattedValue
  }

  const submitForm = (e: any) => {
    e.preventDefault();

    const form = document.querySelector<HTMLFormElement>('#submitFormArezdot');
    if (!form) {
      console.error("Formulário não encontrado.");
      return;
    }

    let whatsapp = document.getElementById('whatsappInput') as HTMLInputElement;
    if (whatsapp.value.length < 14) {
      alert('Por favor, preencha o campo de WhatsApp corretamente.');
      return;
    }

    const url = "https://api.arezdot.com/public/form/integration/answer/67a1bfb1963db80d8fefa1f1";
    const xhr = new XMLHttpRequest();

    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
        console.log(xhr.responseText);
      }
    };

    const data: Record<string, string> = {};
    const inputs = form.elements; // HTMLFormControlsCollection

    for (const input of Array.from(inputs)) {
      if ((input as HTMLInputElement).name) {
        const element = input as HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
        data[element.name] = element.value;
      }
    }

    xhr.send(JSON.stringify(data));
    setFormSent(true);

    try {
      axios.post('https://discord.com/api/webhooks/' + (process.env.REACT_APP_DISCORD_URL ?? ''), {
        content: `@everyone\n\n🚀  NOVO LEAD PARA SITE!  🚀\nNome: ${e.target.name.value}\nWhatsApp: ${e.target['1fA5wAMiP4'].value}\nEmail: ${e.target['bSh1tVefnW'].value}\nNegócio: ${e.target['Im5A01UURb'].value}\nInstagram: ${e.target['8i8UvgdeYe'].value ?? 'Não informado'}\nBora mandar mensagem!`
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        console.log(response);
      }).catch((error) => {
        console.log(error);
      });
    }
    catch (e) {
      console.log('Erro', e);
    }
  };

  const sendNotifToDiscord = () => {
    if (firstClick.current) return;
    try {
      if (process.env.REACT_APP_PRODUCTION === 'true') {
        axios.post('https://discord.com/api/webhooks/' + (process.env.REACT_APP_DISCORD_NV_URL ?? ''), {
          content: `🚀  NOVO VISITANTE em Arezdot Sites!  🚀`
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          console.log(response);
        }).catch((err) => {
          console.log(err);
        });
      }
    }
    catch (err) {
      console.log(err);
    }
    firstClick.current = true;
  }

  useEffect(() => {
    window.addEventListener('click', sendNotifToDiscord);
    window.addEventListener('touchstart', sendNotifToDiscord);
    window.addEventListener('mouseover', sendNotifToDiscord);

    return () => {
      window.removeEventListener('click', sendNotifToDiscord);
      window.removeEventListener('touchstart', sendNotifToDiscord);
      window.removeEventListener('mouseover', sendNotifToDiscord);
    }
  }, []);

  return {
    plans,
    general,
    planPeriod,
    planShowing,
    options,
    init,
    currentFunctionShowing,
    formSent,
    keyBenefits,
    particlesLoaded,
    setPlans,
    setPlanPeriod,
    setPlanShowing,
    checkVisible,
    phoneMask,
    submitForm,
    setFormSent
  }
}

export default useController;