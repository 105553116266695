import styled, { css, keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const popIn = keyframes`
    0% {
        transform: scale(0);
    }
    25% {
        transform: scale(1.1);
    }
    50% {
        transform: scale(0.90);
    }
    75% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
`;

const breathAnimation = keyframes`
    0% { opacity: 0.5; }
    50% { opacity: 1; }
    100% { opacity: 0.5; }
`;

export const mainContainer = styled.div`
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    flex-direction: column;
    overflow-y: auto;
    background: rgba(0, 0, 0, 0.65);
`;

export const header = styled.div`
    width: calc(100% - 20px);
    height: 150px;
    display: flex;
    margin-top: 10px;
    padding: 0 10px;
    align-items: center;
    justify-content: center;

    @media (max-width: 900px) {
        height: 90px;
    }
    
    @media (max-width: 500px) {
        padding: 15px 10px;
        margin-top: 0px;
    }
`;

export const headerLogoWithText = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-left: 10px;
`;

export const headerTitle = styled.h1`
    font-size: 3rem;
    color: #fff;

    @media (max-width: 500px) {
        font-size: 1.8rem;
    }
`;

export const imageLogo = styled.div`
    display: flex;
    flex-direction: column;
    width: 150px;
    height: 150px;
    position: relative;
    
    @media (max-width: 900px) {
        width: 90px;
        height: 90px;
    }
`;

export const imgLogoYellowPart = styled.img`
    position: absolute;
    z-index: 1;
    width: 150px;
    height: 150px;
    animation: ${breathAnimation} 3s infinite;

    @media (max-width: 900px) {
        width: 90px;
        height: 90px;
    }
`;

export const imgLogoDraw = styled.img`
    position: absolute;
    z-index: 2;
    width: 150px;
    height: 150px;

    @media (max-width: 900px) {
        width: 90px;
        height: 90px;
    }
`;

export const buttonsContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: 500px) {
        flex-direction: column;
        align-items: flex-end;
        gap: 3px;
    }
`;

export const button = styled.button`
    width: 175px;
    height: 40px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    background: #fff;
    color: #000;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.8rem;
    background: transparent;
    color: #fff;
    transition-duration: 0.3s;
    border: 1px solid #fff;

    &:hover {
        background: #fff;
        color: #000;
    }

    @media (max-width: 500px) {
        width: 100px;
        height: 25px;
        margin: 0px;
        text-align: end;
        border: none;
        font-size: 0.8rem;

        &:hover {
            background: transparent;
            color: #fff;
            text-decoration: underline;
        }
    }
`;

export const body = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-bottom: 50px;
    height: calc(100% - 50px);
    overflow-y: auto;
`;

export const main = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    margin: 75px 0 100px 0;
    width: 96%;

    @media (max-width: 1366px) {
        margin: 35px 0;
    }

    @media (max-width: 900px) {
        flex-direction: column;
        margin: 60px 0 50px 0;
        gap: 80px;
        width: 90%;
    }

    @media (max-width: 500px) {
        margin: 10px 0 30px 0;
        justify-content: center;
        gap: 40px;
        width: 90%;
    }
`;
export const mainTextArea = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 40%;

    @media (max-width: 900px) {
        width: 96%;
    }

    @media (max-width: 500px) {
        width: 98%;
    }
`;
export const mainTitle = styled.h1`
    font-size: 3.5rem;
    color: #fff;

    @media (max-width: 1366px) {
        font-size: 3rem;
    }

    @media (max-width: 1366px) {
        font-size: 2rem;
    }

    @media (max-width: 900px) {
        font-size: 2rem;
        text-align: center;
    }

    @media (max-width: 500px) {
        font-size: 1.75rem;
    }
`;
export const mainSubtitle = styled.h2`
    font-size: 1.75rem;
    color: #fff;
    margin-top: -20px;

    @media (max-width: 1366px) {
        font-size: 1.2rem;
    }

    @media (max-width: 900px) {
        font-size: 1.1rem;
        text-align: center;
    }
`;
export const freeTrialText = styled.h2`
    font-size: 1.75rem;
    color: #fff;
    width: 100%;
    text-align: center;
    padding: 20px 40px;

    @media (max-width: 1366px) {
        font-size: 1.25rem;
        padding: 0;
    }

    @media (max-width: 900px) {
        font-size: 1.5rem;
    }

    @media (max-width: 500px) {
        font-size: 1.1rem;
    }
`;
export const freeTrialCounter = styled.p`
    font-size: 1.75rem;
    color: #fff;
    width: 100%;
    text-align: center;

    @media (max-width: 1366px) {
        font-size: 1.25rem;
        padding: 0;
    }

    @media (max-width: 900px) {
        font-size: 1.5rem;
    }

    @media (max-width: 500px) {
        font-size: 1.1rem;
    }
`;
export const secureFormText = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    gap: 20px;
    width: 96%;
    text-align: center;
    border-radius: 20px;
    background: none;
    margin-top: 20px;

    @media (max-width: 900px) {
        margin-top: 30px;
    }

    @media (max-width: 500px) {
        margin-top: 20px;
        padding: 10px;
        width: 75%;
    }
`;
export const freeTrialIcon = styled(FontAwesomeIcon)`
    font-size: 1rem;
    color: #fff;

    @media (max-width: 1366px) {
        font-size: 1.25rem;
    }

    @media (max-width: 900px) {
        font-size: 1.25rem;
    }

    @media (max-width: 500px) {
        font-size: 1rem;
    }
`;
export const freeTrialSubtext = styled.p`
    font-size: 0.9rem;
    color: #fff;
    text-align: center;

    @media (max-width: 1366px) {
        font-size: 1rem;
    }

    @media (max-width: 900px) {
        font-size: 1rem;
    }

    @media (max-width: 500px) {
        font-size: .9rem;
    }
`;
export const mainText = styled.p`
    font-size: 1.25rem;
    color: #fff;

    @media (max-width: 1366px) {
        font-size: 1rem;
    }

    @media (max-width: 900px) {
        font-size: 1.2rem;
    }

    @media (max-width: 500px) {
        font-size: 0.9rem;
    }
`;
export const formArea = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 45%;
    border-radius: 20px;

    @media (max-width: 900px) {
        width: 96%;
    }

    @media (max-width: 500px) {
        width: 100%;
    }
`;

const opacityIncrease = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

export const instaFollowAskTitle = styled.h1`
    font-size: 1.3rem;
    color: #fff;
    text-align: center;
    animation: ${opacityIncrease} 0.5s;

    @media (max-width: 500px) {
        font-size: 0.9rem;
    }
`;

export const instaFollowAskText = styled.p`
    font-size: 1rem;
    color: #fff;
    margin: 30px 0;
    text-align: justify;
    animation: ${opacityIncrease} 0.5s;

    @media (max-width: 500px) {
        font-size: 0.85rem;
    }
`;

export const instaFollowAskButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.3);
    color: white;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1rem;
    transition-duration: 0.3s;
    border: 1px solid #fff;
    gap: 15px;
    animation: ${opacityIncrease} 0.5s;
`;

export const instaFollowAskButtonIcon = styled(FontAwesomeIcon)`
    font-size: 2rem;
    transition-duration: 0.05s;
    animation: ${opacityIncrease} 0.5s;

    @media (max-width: 500px) {
        font-size: 1.5rem;
    }
`;

export const instaFollowAskButtonText = styled.p`
    font-size: 1rem;
    animation: ${opacityIncrease} 0.5s;

    @media (max-width: 500px) {
        font-size: 0.85rem;
    }
`;

const showUpAnimation = keyframes`
    0% {
        transform: rotateY(180deg);
    }
    100% {
        transform: rotateY(0deg);
    }
`;

export const instaFollowAsk = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    width: 100%;
    cursor: pointer;
    transition-duration: 0.3s;
    animation: ${showUpAnimation} 0.5s;

    &:hover {
        transform: scale(1.05);
        ${instaFollowAskButton} {
            background: white;
            color: black;
        }
        ${instaFollowAskButtonIcon} {
            transition-duration: 0s !important;
        }
    }
`;

export const form = styled.form`
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
`;

export const label = styled.label`
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: white;
`;

export const input = styled.input`
    font-family: Arial, sans-serif;
    padding: 10px;
    border: none;
    border-bottom: 1px solid white;
    background: transparent;
    color: white;
    margin-bottom: 20px;
    resize: none;
    outline: none;
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
`;

export const textArea = styled.textarea`
    max-height: 300px;
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
    border: none;
    border-bottom: 1px solid white;
    background: transparent;
    color: white;
    margin-bottom: 20px;
    resize: none;
    outline: none;
    font-family: Arial, sans-serif;
`;

export const select = styled.select`
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
    border: none;
    border-bottom: 1px solid white;
    background: transparent;
    color: white;
    margin-bottom: 20px;
    resize: none;
    outline: none;

    option {
        font-family: Arial, sans-serif;
        font-size: 16px;
        font-weight: 600;
        padding: 10px;
        border: none;
        border-bottom: 1px solid white;
        background: transparent;
        color: black;
        margin-bottom: 20px;
        resize: none;
        outline: none;
    }
`;

export const checkboxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    gap: 10px;
`;

export const checkboxes = styled.input`
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin-right: 5px;
    width: 25px;
    height: 25px;
`;

export const submitButton = styled.button`
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 20px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    border: 1px solid white;
    cursor: pointer;
    margin-top: 10px;
    margin-top: 20px;
    transition-duration: 0.3s;

    &:hover {
        background-color: white;
        color: black;
    }
`;

export const titles = styled.div`
    display: flex;
    flex-direction: column;
`;

export const title = styled.h1`
    font-size: 5rem;
    color: #fff;

    @media (max-width: 1366px) {
        font-size: 4rem;
    }

    @media (max-width: 1150px) {
        font-size: 3.5rem;
    }

    @media (max-width: 900px) {
        font-size: 2rem;
    }

    @media (max-width: 500px) {
        font-size: 1.3rem;
        margin: 20px 0 0 0;
    }
`;

export const subtitle = styled.h2`
    font-size: 1.55rem;
    color: #fff;
    width: 100%;

    @media (max-width: 1366px) {
        font-size: 1.25rem;
    }

    @media (max-width: 900px) {
        font-size: 1rem;
    }

    @media (max-width: 500px) {
        font-size: 0.75rem;
    }
`;

export const keyBenefitsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 96%;
    color: #fff;
`;
export const keyBenefit = styled.div<{ index: number }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 300px;
    height: max(fit-content, 200px);
    background: rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    margin: 20px;
    padding: 20px;
    gap: 20px;
    box-shadow: 0 0 15px 1px rgba(255, 255, 255, 0.25);
    transition-duration: 0.3s;

    @media (max-width: 500px) {
        margin: 15px;

    &:hover {
        background: rgba(0, 0, 0, 0.5);
        transform: scale(1.05);
    }
`;
export const keyBenefitTitle = styled.h2`
    font-size: 1.25rem;
    color: #fff;
    text-align: center;
`;
export const keyBenefitDescription = styled.p`
    font-size: 1rem;
    color: #fff;
    text-align: justify;
`;

export const functionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    width: 96%;
    margin: 200px 0;

    @media (max-width: 1366px) {
        margin: 100px 0;
    }

    @media (max-width: 900px) {
        margin: 100px 0;
    }
`;
export const functionsHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 20px;
    margin-bottom: 50px;

    @media (max-width: 500px) {
        margin-bottom: 0px;
    }
`;
export const functionsBits = styled.div<{ items: any[] }>`
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
`;

export const backAndNextButton = styled(FontAwesomeIcon) <{ active: boolean }>`
    display: block;
    width: 50px;
    height: 50px;
    background: transparent;
    border: none;
    cursor: pointer;
    transition-duration: 0.3s;
    color: #fff;
    font-size: 2rem;
    margin: 0 60px;
    transition-duration: 0.2s;
    opacity: ${props => props.active ? 1 : 0.15};

    &:hover {
        ${props => props.active && css`
            transform: scale(1.25);
        `}
    }
    
    @media (max-width: 500px) {
        margin: 0 15px;
        font-size: 1.5rem;
        width: 40px;
        height: 40px;
    }
`;

export const functionBit = styled.div<{ currentFunction: number }>`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    min-width: calc(100% - 100px);
    margin: 0 50px;
    gap: 75px;
    transform: translateX(calc(${props => props.currentFunction * -100}% + ${props => props.currentFunction * -100}px));

    @media (max-width: 1366px) {
        gap: 50px;
    }

    @media (max-width: 900px) {
        margin: 0 25px;
        min-width: calc(100% - 50px);
        transform: translateX(calc(${props => props.currentFunction * -100}% + ${props => props.currentFunction * -50}px));
    }
`;

export const functionInfoArea = styled.div`
    display: flex;
    flex-direction: column !important;
    gap: 20px;
    width: 60%;

    @media (max-width: 900px) {
        width: 96%;
        gap: 25px;
    }

    @media (max-width: 500px) {
        width: 98%;
    }
`;

export const functionImage = styled.img`
    width: 50%;
    border-radius: 10px;
    object-fit: cover;

    @media (max-width: 1366px) {
        width: 60%;
    }

    @media (max-width: 900px) {
        display: none;
    }
`;

export const functionImageMobile = styled.img`
    display: none;

    @media (max-width: 900px) {
        display: flex;
        width: 100%;
        border-radius: 10px;
        object-fit: cover;
    }
`;

export const functionTitle = styled.h1`
    font-size: 1.75rem;
    color: #fff;
    text-align: center;
    width: 900px;

    @media (max-width: 900px) {
        font-size: 1.85rem;
        padding: 10px 10px;
    }

    @media (max-width: 500px) {
        font-size: 1.15rem;
    }
`;

export const functionDescription = styled.p`
    font-size: 1.5rem;
    color: #fff;
    width: 100%;
    margin: 10px 0 40px 0;
    text-align: justify;

    @media (max-width: 1366px) {
        font-size: 1.1rem;
    }

    @media (max-width: 900px) {
        font-size: 1.5rem;
        margin: 10px 0 0 0;
    }

    @media (max-width: 500px) {
        font-size: 1.1rem;
    }
`;


export const functionIcon = styled(FontAwesomeIcon)`
    font-size: 10rem;
    color: #fff;

    @media (max-width: 1366px) {
        font-size: 7rem;
    }

    @media (max-width: 500px) {
        font-size: 4rem;
    }
`;

export const contactButtonIcon = styled(FontAwesomeIcon)`
    font-size: 3.75rem;
    transition-duration: 0.1s !important;

    @media (max-width: 500px) {
        font-size: 3rem;
    }
`;

export const contactButtonText = styled.p`
    font-size: 1.75rem;
    margin-left: 10px;
    transition-duration: 0.1s;

    @media (max-width: 1200px) {
        font-size: 1.5rem;
    }

    @media (max-width: 500px) {
        font-size: 1rem;
    }
`;

export const contactButton = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65%;
    height: 100px;
    border-radius: 5px;
    background: linear-gradient(90deg, rgba(255, 255, 0, 0.20) 1%, rgba(0, 0, 0, 0.8) 100%);
    color: white;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1rem;
    margin: 50px 0 0 0;
    box-shadow: 0 0 15px 1px rgba(255, 255, 255, 0.25);
    transition-duration: 0.3s;
    padding: 20px 10px;
    
    gap: 30px;
    text-decoration: none;

    @media (max-width: 1200px) {
        margin: 50px 0 25px 0;
        padding: 15px 10px;
        width: 80%;
        font-size: 0.9rem;
    }

    @media (max-width: 500px) {
        margin: 30px 0 25px 0;
        padding: 10px 5px;
        font-size: 0.8rem;
        width: 85%;
        height: 75px;
    }
        
    &:hover {
        transform: scale(1.1);
    }
`;

export const plansArea = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 0 75px 0;
`;

export const itemTitle = styled.h1`
    font-size: 1.75rem;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    width: 900px;

    @media (max-width: 900px) {
        font-size: 1.85rem;
        padding: 10px 10px;
        width: 90%;
    }

    @media (max-width: 500px) {
        font-size: 1.15rem;
    }
`;
export const itemKeyBenefitsTitle = styled.h1`
    font-size: 2rem;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    width: 85%;
    margin: 75px 0 40px 0;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);

    @media (max-width: 900px) {
        font-size: 1.75rem;
        margin-top: 100px;
        padding: 10px 10px;
    }

    @media (max-width: 500px) {
        font-size: 1.15rem;
        margin-top: 50px;
    }
`;

export const plansContainer = styled.div<{ planShowing: number }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    svg:first-child {
        opacity: ${props => props.planShowing === 0 ? 0 : 1};
    }
    
    svg:last-child {
        opacity: ${props => props.planShowing === 2 ? 0 : 1};
    }
`;

export const plansNextBackButton = styled(FontAwesomeIcon) <{ index: number }>`
    display: none;
    width: 50px;
    height: 50px;
    background: transparent;
    border: none;
    cursor: pointer;
    transition-duration: 0.3s;
    color: #fff;
    font-size: 2rem;
    margin: 0 60px;
    transition-duration: 0.1s;

    @media (max-width: 900px) {
        display: block;
    }

    @media (max-width: 500px) {
        margin: 0 15px;
        font-size: 1.5rem;
        width: 40px;
        height: 40px;
    }
`;

export const plansBits = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    width: 80%;

    @media (max-width: 1200px) {
        gap: 40px;
    }

    @media (max-width: 900px) {
        gap: 25px;
    }

    @media (max-width: 500px) {
        gap: 10px;
    }
`;

export const planBit = styled.div<{ planSelected: number, showPlan: boolean }>`
    display: flex;
    flex-direction: column;
    width: 350px;
    height: 450px;
    background: linear-gradient(180deg, rgba(255, 255, 0, 0.20) 1%, rgba(0, 0, 0, 0.8) 100%);
    box-shadow: 0 0 15px 1px rgba(255, 255, 255, 0.25);
    border-radius: 10px;
    padding: 25px 10px;
    gap: 10px;
    transition-duration: 0.3s;
    cursor: pointer;
    animation: ${popIn} 0.5s;

    &:hover {
        transform: scale(1.05);

        .hire_btn {
            background: #fff;
            color: #000;
        }
    }

    @media (max-width: 1200px) {
        height: 400px;
    }

    @media (max-width: 900px) {
        display: ${props => props.showPlan ? 'flex' : 'none'};
        width: 85%;
        height: 550px;
    }

    @media (max-width: 500px) {
        height: 420px;
        width: 95%;
    }
`;

export const planTitle = styled.h1`
    font-size: 1.25rem;
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;

    @media (max-width: 1200px) {
        font-size: 1.1rem;
    }
`;
export const planSubtitle = styled.h2`
    font-size: 1rem;
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;

    @media (max-width: 1200px) {
        font-size: 1rem;
    }
`;

export const planItems = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
    scroll-behavior: smooth;
    overflow-y: auto;
    padding: 10px;
`;

export const planItem = styled.p`
    font-size: 0.8rem;
    color: white;
    
    &::before {
        content: '»';
        margin-right: 5px;
        font-size: 1.2rem;
        font-weight: bold;
    }

    @media (max-width: 900px) {
        font-size: 0.8rem;
    }

    @media (max-width: 500px) {
        font-size: 0.8rem;
    }
`;

export const planValue = styled.p`
    font-size: 1.3rem;
    color: #00ff00;
    font-weight: bold;
    text-align: center;

    @media (max-width: 900px) {
        font-size: 1rem;
    }

    @media (max-width: 500px) {
        font-size: 1.1rem;
    }
    
    > .planValueExtra {
        font-size: 1rem;
        color: #00ff00;
        font-weight: bold;
        text-align: center;
        text-decoration: line-through;

        @media (max-width: 900px) {
            font-size: 0.8rem;
        }

        @media (max-width: 500px) {
            font-size: 0.85rem;
        }
    }
`;
export const planButton = styled.div`
    width: 80%;
    padding: 8px 0;
    background: #000;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition-duration: 0.3s;
    font-size: 0.9rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
    align-self: center;
    text-align: center;
    border: 1px solid #fff;

    &:hover {
        background: #fff;
        color: #000;
    }

    @media (max-width: 900px) {
        font-size: 0.9rem;
    }

    @media (max-width: 500px) {
        font-size: 0.9rem;
    }
`;

export const plansPeriods = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    width: 100%;
    margin: 15px 0 50px 0;

    :nth-child(2) {
        border-right: 1px solid #fff;
        border-left: 1px solid #fff;
        padding: 0 50px;
    }

    @media (max-width: 900px) {
        gap: 25px;
    }

    @media (max-width: 500px) {
        gap: 10px;
        :nth-child(2) {
        padding: 0 10px;
    }
    }
`;

export const planPeriod = styled.p<{ selected: boolean }>`
    font-size: 1.2rem;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    transition-duration: 0.3s;
    text-transform: uppercase;
    text-align: center;


    ${props => props.selected ? css`
        text-decoration: underline;
        color: rgba(255, 255, 0, 0.75);
        opacity: 1;
    ` : css`
        opacity: 0.5;
    `}

    @media (max-width: 900px) {
        width: 33%;
        font-size: 1rem;
    }

    @media (max-width: 500px) {
        font-size: 0.8rem;
    }

    &:hover {
        opacity: 1;
    }
`;
export const shortcutIcons = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 50px;
    bottom: 10px;
    top: auto;
    right: 10px;
    left: auto;
    gap: 20px;
    z-index: 9999;

    @media (max-width: 900px) {
        bottom: 30px;
    }

    @media (max-width: 500px) {
        gap: 10px;
    }
`;
export const shortcutIconDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: rgba(255, 255, 0, 0.5);
    cursor: pointer;
    transition-duration: 0.3s;
    animation: ${popIn} 0.5s forwards;

    &:hover {
        background: rgba(255, 255, 0, 0.75);
    }

    @media (max-width: 500px) {
        width: 50px;
        height: 50px;
    }
`;
export const shortcutIcon = styled(FontAwesomeIcon)`
    font-size: 1.5rem;
    color: #000;
`;
