import React, { useState, useEffect } from 'react';
import { useController } from './controller';
import * as S from './styles';
import * as icons from '@fortawesome/free-solid-svg-icons'
import * as iconBrands from '@fortawesome/free-brands-svg-icons'
import { Helmet } from 'react-helmet';
import Particles from "@tsparticles/react";
import moment from 'moment-timezone';
import axios from 'axios';

const Websites = (props: any) => {
    const controller = useController();

    return (
        <>
            <Particles
                id="tsparticles"
                particlesLoaded={controller.particlesLoaded as any}
                options={controller.options}
            />
            <S.shortcutIcons id="'shortcutIcons'>">
                <S.shortcutIconDiv onClick={() => {
                    window.open('https://api.whatsapp.com/send?phone=553598811741&text=Ol%C3%A1!%20Gostaria%20de%20saber%20mais%20sobre%20os%20sites%20da%20Arezdot!.', '_blank');
                }}>
                    {/* @ts-ignore */}
                    <S.shortcutIcon icon={iconBrands.faWhatsapp} />
                </S.shortcutIconDiv>
            </S.shortcutIcons>
            <S.mainContainer>
                <Helmet>
                    <title>Arezdot Sites | Construção de sites rápidos e completos para seu negócio</title>
                    <meta name="description" content="Crie seu site profissional com a Arezdot! Sites rápidos, responsivos e otimizados para atrair visitantes e gerar vendas automaticamente. Solicite seu orçamento gratuito!" />
                    <meta property="og:title" content="Arezdot Sites | Construção de sites rápidos e completos para seu negócio" />
                    <meta property="og:description" content="Crie seu site profissional com a Arezdot! Sites rápidos, responsivos e otimizados para atrair visitantes e gerar vendas automaticamente. Solicite seu orçamento gratuito!" />
                </Helmet>
                <S.body>
                    <S.header id="pageTop">
                        <S.headerLogoWithText>
                            <S.imageLogo>
                                <S.imgLogoYellowPart alt="Imagem de logo do Arezdot ERP" src="/app/images/logo/logo_arezdot_glow_only.png" />
                                <S.imgLogoDraw alt="Imagem de logo do Arezdot ERP" src="/app/images/logo/logo_arezdot_draw_only.png" />
                            </S.imageLogo>
                            <S.headerTitle>{'Arezdot'.toUpperCase()}</S.headerTitle>
                        </S.headerLogoWithText>
                    </S.header>
                    <S.main>
                        <S.mainTextArea>
                            <S.mainTitle>Seu Site Profissional A partir de R$ 750!</S.mainTitle>
                            <S.mainSubtitle>Destaque-se no digital! Tenha um site profissional que vende para você 24h por dia.</S.mainSubtitle>
                            <S.mainText>
                                ❌ Pare de perder clientes por não ter um site profissional! < br />< br />
                                📈 Crie sua presença online com um site rápido, responsivo e otimizado, pronto para atrair visitantes e gerar vendas automaticamente. < br />< br />
                                💡 Seja encontrado, aumente sua credibilidade e transforme sua presença digital em um ativo lucrativo para o seu negócio!
                            </S.mainText>
                        </S.mainTextArea>
                        <S.formArea>
                            {!controller.formSent && (<S.freeTrialText>Preencha abaixo e receba seu orçamento gratuito!{/* <S.freeTrialCounter>(Válido até {moment().tz('America/Sao_Paulo').add(14, 'days').format('DD/MM/YYYY')})</S.freeTrialCounter> */}</S.freeTrialText>)}

                            {!controller.formSent ? (
                                <S.form id="submitFormArezdot" onSubmit={(e: any) => {
                                    e.preventDefault();
                                    controller.submitForm(e)
                                }}>
                                    <input type="hidden" name="answer_start" value={new Date().toISOString()} />
                                    <S.label>Nome Completo*</S.label>
                                    <S.input autoCapitalize='words' autoComplete="off" type="text" name="name" required />
                                    <S.label>WhatsApp (Com DDD)*</S.label>
                                    <S.input id="whatsappInput" inputMode='numeric' onKeyUp={() => { controller.phoneMask('1fA5wAMiP4') }} autoComplete="off" type="text" name="1fA5wAMiP4" required />
                                    <S.label>Email*</S.label>
                                    <S.input autoComplete="off" type="email" name="bSh1tVefnW" required />
                                    <S.label>Qual é o seu tipo de negócio?*</S.label>""
                                    <S.select name="Im5A01UURb">
                                        <option value="Negócios Locais (salões, academias, restaurantes, lojas físicas...)">Negócios Locais (salões, academias, restaurantes, lojas físicas...)</option>
                                        <option value="Profissionais Autônomos (advogados, dentistas, terapeutas, psicólogos...)">Profissionais Autônomos (advogados, dentistas, terapeutas, psicólogos...)</option>
                                        <option value="E-commerce e Lojas Virtuais">E-commerce e Lojas Virtuais</option>
                                        <option value="Influenciadores e Criadores de Conteúdo">Influenciadores e Criadores de Conteúdo</option>
                                        <option value="Outro">Outro</option>
                                    </S.select>
                                    <S.label>Seu Instagram (se tiver)</S.label>
                                    <S.input autoComplete="off" type="text" name="8i8UvgdeYe" />
                                    <S.submitButton type="submit">Solicitar Meu Site Agora</S.submitButton>
                                    <S.secureFormText>
                                        <S.freeTrialIcon icon={icons.faLock} />
                                        <S.freeTrialSubtext>Seus dados estão seguros e não serão compartilhados.</S.freeTrialSubtext>
                                    </S.secureFormText>
                                </S.form>
                            ) : (
                                <S.instaFollowAsk onClick={() => {
                                    window.open('https://www.instagram.com/arezdot_/', '_blank');
                                }}>
                                    <S.instaFollowAskTitle>🎉 Parabéns! Seu próximo nível começa agora! 🚀</S.instaFollowAskTitle>
                                    <S.instaFollowAskText>
                                        Você deu um grande passo para fortalecer sua presença online e atrair mais clientes!<br /><br />
                                        Mas isso é só o começo! No nosso Instagram, compartilhamos <strong>conteúdos exclusivos</strong> para empresários como você, que desejam <strong>crescer, se destacar e maximizar seus resultados.</strong><br /><br />
                                        ✅ <strong>Dicas práticas</strong> para otimizar sua presença digital.<br />
                                        ✅ <strong>Estratégias inteligentes</strong> para impulsionar suas vendas.<br />
                                        ✅ <strong>Inspiração e motivação</strong> para superar desafios e alcançar novas conquistas.<br /><br />
                                        <strong>Junte-se à nossa comunidade e esteja sempre à frente!</strong><br />
                                        Clique abaixo para acessar nosso perfil e acompanhar insights que fazem a diferença. 🔥🔥
                                    </S.instaFollowAskText>
                                    <S.instaFollowAskButton>
                                        {/* @ts-ignore */}
                                        <S.instaFollowAskButtonIcon icon={iconBrands.faInstagram} />
                                        <S.instaFollowAskButtonText>Seguir Arezdot no Instagram</S.instaFollowAskButtonText>
                                    </S.instaFollowAskButton>
                                </S.instaFollowAsk>
                            )}
                        </S.formArea>
                    </S.main>
                    <S.itemKeyBenefitsTitle>Por que Escolher a Arezdot para Criar meu Site? 🚀</S.itemKeyBenefitsTitle>
                    <S.keyBenefitsContainer>
                        {controller.keyBenefits.map((item, index) => {
                            return (
                                <S.keyBenefit index={index} key={'keyBenefit_' + index}>
                                    <S.keyBenefitTitle>{item.title}</S.keyBenefitTitle>
                                    <S.keyBenefitDescription>{item.description}</S.keyBenefitDescription>
                                </S.keyBenefit>
                            )
                        })}
                    </S.keyBenefitsContainer>
                </S.body>
            </S.mainContainer>
        </>
    )
}

export default Websites;